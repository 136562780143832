<template>
  <section id="film-distributor" v-loading="updateLoading">
    <b-card>
      <div class="title">
        <p>{{ infoPageTitle }}</p>
      </div>

      <div>
        <b-button variant="outline-secondary" @click="goBackInvestInfo()">
          回目錄
        </b-button>
      </div>
      <div>
        <b-form ref="form">
          <div v-for="item in items" :id="item.id" :key="item.id">
            <b-row :id="item.id" :key="item.id" ref="row">
              <b-col cols="12" class="mt-2">
                <p class="sub-title">
                  Production company ID :
                  {{ pageIdVal == "edit" ? item.id : "Create New ID" }}
                </p>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Production company Name"
                  label-for="invest-name-en"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="invest-name-en"
                    label="導演名稱"
                    v-model="item.production_name_en"
                    name="production_name_en"
                    :disabled="disableChange"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="出品公司名稱"
                  label-for="invest-name-ch"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="invest-name-ch"
                    label="出品公司名稱"
                    v-model="item.production_name_zh_hk"
                    name="production_name_zh_hk"
                    :disabled="disableChange"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Production company Code (10位)"
                  label-for="invest-name-code"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="invest-name-code"
                    label="Production company Code (10位)"
                    v-model="item.production_code"
                    name="production_code"
                    :disabled="disableChange"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="地區" label-for="area" label-cols-md="3">
                  <b-form-select
                    v-model="item.area"
                    :options="selectOptions"
                    :disabled="disableChange"
                    @change="selectCoProd"
                    :clearable="false"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="合拍片"
                  label-for="co_production"
                  label-cols-md="3"
                >
                  <b-form-select
                    v-model="item.co_production"
                    @change="selectCoProd"
                    :options="selectCoOptions"
                    :disabled="disableChange"
                    :clearable="false"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Remark"
                  label-for="remark"
                  label-cols-md="3"
                >
                  <b-form-textarea
                    id="prod_remark"
                    v-model="item.remark"
                    placeholder=""
                    rows="3"
                    max-rows="6"
                    :disabled="disableChange"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col v-if="pageType == 'edit'">
                <div class="d-flex justify-content-end">
                  <b-button
                    @click="disableChange = false"
                    class="mr-1"
                    v-if="disableChange == true"
                    variant="outline-primary"
                    >修改</b-button
                  >
                  <b-button
                    class="mr-1"
                    @click="submitForm('save', 0)"
                    v-if="disableChange == false"
                    variant="outline-primary"
                    >修正上方紀錄</b-button
                  >
                  <b-button
                    @click="submitForm('addEditSave', 1)"
                    v-if="disableChange == false"
                    class="mr-1"
                    variant="outline-primary"
                    >修訂紀錄並紀錄舊名</b-button
                  >
                </div>
              </b-col>
            </b-row>
            <div v-if="item.old_record && item.old_record.length > 0">
              <b-col cols="12">
                <hr />
              </b-col>
              <b-row v-for="(item, index) in item.old_record" :key="index">
                <b-col cols="3">
                  <b-form-group
                    label="出品公司名稱 (英文舊名)"
                    label-for="publisher-english-old"
                  >
                    <div class="text-wrap:wrap input-box-style">
                      {{ item.name_en }}
                    </div>
                    <!-- <b-form-input
                      id="publisher-english-old"
                      label="出品公司名稱 (英文舊名)"
                      rows="3"
                      v-model="item.name_en"
                      name="name_en"
                      :disabled="true"
                    /> -->
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group
                    label="出品公司名稱 (中文舊名)"
                    label-for="publisher-chinese-old"
                  >
                    <div class="text-wrap:wrap input-box-style">
                      {{ item.name_zh_hk }}
                    </div>
                    <!-- <b-form-input
                      id="publisher-chinese-old"
                      label="出品公司名稱 (中文舊名)"
                      rows="3"
                      v-model="item.name_zh_hk"
                      name="name_zh_hk"
                      :disabled="true"
                    /> -->
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group label="出品公司簡稱" label-for="publisher-code">
                    <div class="text-wrap:wrap input-box-style">
                      {{ item.code }}
                    </div>
                    <!-- <b-form-input
                      id="publisher-chinese-old"
                      label="出品公司名稱 (中文舊名)"
                      rows="3"
                      v-model="item.name_zh_hk"
                      name="name_zh_hk"
                      :disabled="true"
                    /> -->
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group label="更改記錄日期" label-for="name-date">
                    <datePickerCom
                      :isDisabled="item.disabled"
                      :pickTimeVal.sync="item.change_date"
                      :inputWidth="'100%'"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="2" class="margin-auto">
                  <div
                    style="margin-top: 27px"
                    class="b-flex d-flex justify-content-center"
                  >
                    <feather-icon
                      v-if="
                        userInfo.role == 'Super Admin' &&
                        pageType === 'edit' &&
                        !item.disabled
                      "
                      v-b-tooltip.hover
                      title="Save"
                      size="25"
                      @click="historySave(item.id, index)"
                      icon="SaveIcon"
                      class="mr-3 cursor-pointer"
                      color="#0e72bc"
                    />
                    <feather-icon
                      v-if="
                        userInfo.role == 'Super Admin' &&
                        pageType === 'edit' &&
                        item.disabled
                      "
                      @click="editChange(index, 'edit')"
                      size="25"
                      v-b-tooltip.hover
                      title="Edit"
                      icon="EditIcon"
                      class="mr-3 cursor-pointer"
                      color="#0e72bc"
                    />
                    <feather-icon
                      v-if="
                        userInfo.role == 'Super Admin' &&
                        pageType === 'edit' &&
                        !item.disabled
                      "
                      @click="deleteHistory(item.id)"
                      size="25"
                      v-b-tooltip.hover
                      title="Delect"
                      icon="Trash2Icon"
                      color="red"
                      class="mr-3 cursor-pointer"
                    />
                    <span
                      v-if="!item.disabled && pageType === 'edit'"
                      @click="editChange(index, 'cancel')"
                      >取消</span
                    >
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col cols="12">
                <hr />
              </b-col>
              <!-- submit and reset -->
              <b-col
                cols="12"
                v-if="disableChange == false && pageType == 'edit'"
              >
                <b-button
                  type="button"
                  variant="outline-primary"
                  style="display: block; margin: 0 auto"
                  @click="editChange('test', 'all-cancel')"
                >
                  <span> 取消編輯 </span>
                </b-button>
              </b-col>
              <b-col offset-md="5" v-if="pageType === 'create'">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="submitForm('create', 0)"
                  :disabled="disableChange"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </div>
    </b-card>
  </section>
</template>
<script>
import {
  createProduction,
  editProduction,
} from "@/api/film-material-management/productionCompany"
import { getSelfProfile } from "@/api/login"
import {
  changeOldRecord,
  deleteOldRecord,
} from "@/api/film-material-management/oldRecord"
import datePickerCom from "@/views/components/datePicker.vue"

export default {
  name: "ProductionCompanyForm",
  components: { datePickerCom },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [
        {
          id: "",
          production_name_en: "",
          production_name_zh_hk: "",
          production_code: "",
          area: "",
          old_record: [],
          remark: "",
        },
      ],
    },
  },
  data() {
    return {
      dateFormat: "d-m-Y",
      selectCoOptions: [
        { text: "yes", value: 1 },
        { text: "no", value: 0 },
      ],
      userInfo: "",
      disableChange: true,
      borderClass: "",
      pageId: "",
      pagePath: "",
      pageType: "",
      select: "香港",
      selectOptions: [
        { text: "香港", value: "Hong Kong" },
        { text: "非香港", value: "Non Hong Kong" },
      ],
      // dateDefault: 'today',
      formdata: {
        id: "",
        production_name_en: "",
        production_name_zh_hk: "",
        production_code: "",
        area: "",
        co_production: "",
        old_record: [],
      },
    }
  },
  watch: {
    $route(to, from) {
      if (to.query.type !== from.query.type) {
        this.pageType = to.query.type

        this.toEdit()
      }
    },
  },
  created() {
    this.pageType = this.$route.query.type
    this.pageId = this.$route.query.id
    this.pagePath = this.$route.path
    console.log(this.pageType)
    if (this.pageType === "review") {
      // this.disableChange = false
    } else if (this.pageType === "edit") {
      // this.dateDefault = this.items[0].old_record[0].change_date
      this.borderClass = "redborder"
      this.getSelfProfile()
    } else {
      this.disableChange = false
    }
  },
  computed: {
    infoPageTitle() {
      if (this.pageType === "review") {
        return `${this.title}記錄`
      } else if (this.pageType === "edit") {
        return `修訂${this.title}記錄`
      } else {
        return `開立新${this.title}記錄`
      }
    },
    pageIdVal() {
      if (this.pageType === "review") {
        return "edit"
      } else if (this.pageType === "edit") {
        return "edit"
      } else {
        return "Create New ID"
      }
    },
    updateLoading: {
      get() {
        return this.loading
      },
      set(value) {
        this.$emit("update:loading", value)
      },
    },
  },
  methods: {
    selectCoProd(val) {
      if (
        val === 1 ||
        val === "Non Hong Kong" ||
        this.items[0].area !== "Non Hong Kong" ||
        this.items[0].co_production === 1
      ) {
        if (
          this.items[0].production_code &&
          this.items[0].production_code !== " " &&
          !this.items[0].production_code.includes("*")
        ) {
          this.items[0].production_code = `${this.items[0].production_code}*`
        }
        if (!this.items[0].production_name_en.includes("*")) {
          this.items[0].production_name_en = `${this.items[0].production_name_en}*`
        }
        if (!this.items[0].production_name_zh_hk.includes("*")) {
          this.items[0].production_name_zh_hk = `${this.items[0].production_name_zh_hk}*`
        }
      }
      if (
        this.items[0].co_production === 0 &&
        this.items[0].area !== "Non Hong Kong"
      ) {
        this.items[0].production_code = this.items[0].production_code.replace(
          "*",
          ""
        )
        this.items[0].production_name_en =
          this.items[0].production_name_en.replace("*", "")
        this.items[0].production_name_zh_hk =
          this.items[0].production_name_zh_hk.replace("*", "")
      }
      this.items[0].co_production =
        this.items[0].area === "Non Hong Kong" ? 1 : 0
    },
    deleteHistory(id) {
      deleteOldRecord("production", id)
        .then((res) => {
          console.log(res)
          this.$emit("getProductionDetail")
        })
        .catch((error) => {
          console.log(error)
        })
    },
    historySave(id, index) {
      this.updateLoading = true
      console.log(this.items[0].old_record[index])
      const data = {
        name_en: this.items[0].old_record[index].name_en,
        name_zh_hk: this.items[0].old_record[index].name_zh_hk,
        change_date: this.items[0].old_record[index].change_date,
        code: this.items[0].old_record[index].code,
      }
      changeOldRecord("production", id, data)
        .then((res) => {
          console.log(res)
          this.updateLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.updateLoading = false
        })
    },
    editChange(index, type) {
      if (type === "edit") {
        this.items[0].old_record[index].disabled = false
      } else if (type === "cancel") {
        this.items[0].old_record[index].disabled = true
        this.$emit("getProductionDetail")
      } else if (type === "all-cancel") {
        this.disableChange = true
        this.items[0].old_record.map((el) => {
          el.disabled = true
        })
        this.$emit("getProductionDetail")
      }
    },
    getSelfProfile() {
      getSelfProfile()
        .then((res) => {
          this.userInfo = res.data
          console.log(this.userInfo)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    goBackInvestInfo() {
      this.$router.push({ name: "production-company" })
    },
    toEdit() {
      this.$router.push({
        path: this.pagePath,
        query: { type: "edit", id: this.pageId },
      })
      this.disableChange = false
      this.borderClass = "redborder"
    },
    submitForm(type, revise) {
      const data = {
        production_name_en: this.items[0].production_name_en,
        production_name_zh_hk: this.items[0].production_name_zh_hk,
        production_code: this.items[0].production_code,
        area: this.items[0].area,
        is_jv: this.items[0].co_production,
        revise,
        remark: this.items[0].remark,
      }
      console.log(type, data)
      if (type === "create") {
        createProduction(data)
          .then((res) => {
            this.formdata.production_name_en = res.data.production_name_en
            this.formdata.production_name_zh_hk = res.data.production_name_zh_hk
            this.formdata.production_code = res.data.production_code
            this.formdata.area = res.data.area
            console.log(res)
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        editProduction(this.items[0].id, data)
          .then((res) => {
            console.log(res)
            this.$emit("getProductionDetail")
          })
          .catch((error) => {
            console.log(error)
          })
      }
      // this.$router.push({ name: 'production-company' })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.sub-title {
  width: 100%;
  color: #0e72bc;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.line {
  height: 3px;
  color: #0e72bc;
}
.redborder {
  border-color: red;
}
</style>
